import React, { createContext, useState } from "react";

interface IThemeContext {
  theme: string;
  toggleTheme(event: React.MouseEvent<HTMLButtonElement>): void;
}

export const ThemeContext = createContext({
  theme: "",
  toggleTheme: () => {},
} as IThemeContext);

type ThemeProviderProps = {
  children: React.ReactNode;
};

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState("light");

  function toggleTheme() {
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }

  const ctx = {
    theme,
    toggleTheme,
  };

  return (
    <ThemeContext.Provider value={{ ...ctx }}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;
