import { graphql, useStaticQuery } from "gatsby";

const useReasons = () => {
  const data = useStaticQuery(graphql`
    query ReasonsQuery {
      # Filters 'null', returns Reasons and their number
      allMdx(filter: { frontmatter: { number: { ne: null } } }) {
        edges {
          node {
            id
          }
        }
      }
    }
  `);
  // console.log(data.allMdx.edges.nodes);
  return data.allMdx.edges.length;
};

export default useReasons;
