// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-work-index-tsx": () => import("./../../../src/pages/work/index.tsx" /* webpackChunkName: "component---src-pages-work-index-tsx" */),
  "component---src-templates-project-index-tsx": () => import("./../../../src/templates/project/index.tsx" /* webpackChunkName: "component---src-templates-project-index-tsx" */),
  "component---src-templates-reason-index-tsx": () => import("./../../../src/templates/reason/index.tsx" /* webpackChunkName: "component---src-templates-reason-index-tsx" */)
}

