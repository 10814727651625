import React, { createContext, useState } from "react";

interface IToggleContext {
  drawerIsOpen: boolean;
  toggleDrawer(
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ): void;
  modalIsOpen: boolean;
  toggleModal: () => void;
}

export const ToggleContext = createContext({
  drawerIsOpen: false,
  toggleDrawer: () => {},
  modalIsOpen: false,
  toggleModal: () => {},
} as IToggleContext);

type ToggleProviderProps = {
  children: React.ReactNode;
};

const ToggleProvider = ({ children }: ToggleProviderProps) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function toggleDrawer() {
    setDrawerIsOpen(() => (drawerIsOpen ? false : true));
  }
  function toggleModal() {
    setModalIsOpen(() => (modalIsOpen ? false : true));
  }

  const ctx = {
    drawerIsOpen,
    toggleDrawer,
    modalIsOpen,
    toggleModal,
  };

  return (
    <ToggleContext.Provider value={{ ...ctx }}>
      {children}
    </ToggleContext.Provider>
  );
};

export default ToggleProvider;
