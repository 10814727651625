import React, { createContext, useState } from "react";
import useReasons from "../hooks/useReasons";

export const ReasonContext = createContext({
  reasonNumber: 1,
  nextReasonNumber: () => {},
});

type ReasonProviderProps = {
  children: React.ReactNode;
};

const ReasonProvider = ({ children }: ReasonProviderProps) => {
  const length = useReasons();
  const [reasonNumber, setReasonNumber] = useState(1);

  const nextReasonNumber = () => {
    let newNumber = Math.floor(Math.random() * length) + 1;
    setReasonNumber((prevNumber) => {
      if (newNumber === prevNumber) {
        // If the new number is 1 and is the same as the previous number...
        if (newNumber === 1) {
          // And that number is 1... Add 1 to it
          return (newNumber += 1);
        } else if (newNumber === length) {
          // Or that number is "length"... Minus 1 from it
          return (newNumber -= 1);
        } else {
          // Otherwise, just add 1 to it.
          return (newNumber += 1);
        }
      } else {
        // Else, just return the random number...
        return newNumber;
      }
    });
  };

  const ctx = {
    reasonNumber,
    nextReasonNumber,
  };
  return (
    <ReasonContext.Provider value={{ ...ctx }}>
      {children}
    </ReasonContext.Provider>
  );
};

export default ReasonProvider;
