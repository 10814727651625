import React from "react";
import ToggleProvider from "./src/context/toggleProvider";
import ThemeProvider from "./src/context/themeProvider";
import ReasonProvider from "./src/context/reasonProvider";
import "@fontsource/open-sans/400.css";
import "@fontsource/work-sans/300.css";
import "./src/styles/global.scss";

export const wrapRootElement = ({ element }) => {
  return (
    <ReasonProvider>
      <ThemeProvider>
        <ToggleProvider>{element}</ToggleProvider>
      </ThemeProvider>
    </ReasonProvider>
  );
};
